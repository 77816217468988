<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/help' }">Help</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="help-content">
      <h2>Citation</h2>
      <p>A global overview of genetically interpretable multimorbidities among common diseases in the UK Biobank (https://genomemedicine.biomedcentral.com/articles/10.1186/s13073-021-00927-6).
      </p>
      <h2>Functions of Multimorbidity</h2>
      <p>Multimorbidity is a web portal for researchers, doctors and patients to browse and search the multimorbidities
        among common diseases in the UK Biobank. The main functions of Multimorbidity include:</p>
      <ol>
        <li>
          <p>1. Allow users to browse the summary information of diseases, including disease related prevalence, sex, age, genetic
          components, multimorbidities, and multimorbidities mediated by genetic components.</p>
        </li>
        <li>
          <p>2. Allow users to search the multimorbidities involving diseases that they are interested.</p>
        </li>
        <li>
          <p>3. Allow users to search the multimorbidities mediated by the SNPs, genes, PPIs, pathways that they are interested.</p>
        </li>
        <li>
          <p>4. Provide the common diseases information, disease genetic components, multimorbidities, and genetic components mediated multimorbidities for downloads.</p>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style lang="less">
.help-content{
  padding-left: 100px;
  text-align: left;
  p{
    text-indent: 2em;
    line-height: 30px;
  }
}
</style>
